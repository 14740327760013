import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components/macro'
import HeroBackground from './HeroBackground'
import Container from './Container'
import media from '../styles/media'
import { shadeColor } from '../utils/colorShades'

const Title = styled.h1`
  font-size: 1.8rem;
  font-weight: 800;
  color: white;
  margin-top: 0;
  z-index: 1;
  margin-bottom: 1.5rem;

  ${media.tablet`
    font-size: 3rem;
  `};
`

const Subtitle = styled.p`
  font-size: 1.03rem;
  color: var(--color-text-light);
  line-height: 1.75;
  z-index: 1;

  ${media.tablet`
    font-size: 1.2rem;
    line-height: 1.85;
    width: 80%;
  `};
`

const Button = styled.button`
  align-self: flex-start;
  padding: 1rem;
  color: white;
  border-radius: 4px;
  text-decoration: none;
  text-align: center;
  font-family: var(--font-heading);
  transition: all 0.2s;
  font-size: 1rem;
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.01);

  ${media.tablet`
    padding: 1.5rem;
  `};
`

const Wrapper = styled.section`
  background-color: ${props =>
    props.backgroundColour ? props.backgroundColour : 'var(--color-body-dark)'};
  ${Container} {
    position: relative;
    display: flex;
    height: 100vh;
    flex-flow: column wrap;
    justify-content: center;
    align-content: center;

    &.has-bg {
      width: 100%;
      max-width: 100%;
      height: auto;
      margin: 0;
      padding: 0;
      text-align: center;
      padding-top: 8rem;

      ${Title} {
        color: white;
        width: 100%;
        font-size: 1.8rem;
        text-transform: uppercase;

        ${media.tablet`
          font-size: 2.8rem;
        `};
      }

      ${Subtitle} {
        color: white;
        opacity: 0.8;
        font-size: 1rem;
        margin: auto 1.5rem 0rem;

        ${media.tablet`
          max-width: 60%;
          font-size: 1.15rem;
          margin: auto auto 2rem;
        `};

        p {
          line-height: 1.75;
        }
      }

      ${Button} {
        align-self: center;
      }
    }

    ${Title} {
      font-size: ${props => (props.isHome ? '3rem' : '2.8rem')};
    }

    ${Subtitle} {
      ~ ${Button} {
        margin-top: 2rem;
      }
    }

    ${Button} {
      background-color: ${props =>
        props.backgroundColour
          ? shadeColor(`${props.backgroundColour}`, 15)
          : 'var(--color-primary)'};

      &:hover {
        background-color: ${props =>
          props.backgroundColour
            ? shadeColor(`${props.backgroundColour}`, 10)
            : 'var(--color-primary)'};
      }
    }
  }
`

const BgImg = styled(Img)`
  margin: 2rem auto 0;
  width: 90%;
  z-index: 1;
  height: auto;
  overflow: auto !important;

  ${media.tablet`
    width: 66%;
    margin: 4rem auto 0;
  `};
  /* @media (min-width: ${props => props.theme.responsive.small}) {
    height: ${props => props.height || 'auto'};
  } */
  & > img {
    object-fit: ${props => props.fit || 'initial'} !important;
    object-position: ${props => props.position || '50% 50%'} !important;
  }
  /* &::before {
    content: '';
    background: rgba(0, 0, 0, 0.55);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
  } */
`

const Hero = ({
  height,
  image,
  title,
  subtitle,
  isHome,
  backgroundColour,
  websiteUrl,
  ctaButtonText,
}) => (
  <Wrapper backgroundColour={backgroundColour} isHome={isHome}>
    <Container className={image && 'has-bg'}>
      {isHome && <HeroBackground />}
      <Title>{title && title}</Title>
      <Subtitle
        dangerouslySetInnerHTML={{
          __html: subtitle,
        }}
      />
      {websiteUrl && (
        <Button
          as="a"
          href={websiteUrl}
          target="_blank"
          rel="noreferrer noopener"
        >
          {ctaButtonText || 'View website'}
        </Button>
      )}
      {image && (
        <BgImg
          height={height}
          fluid={image && image.fluid}
          imgStyle={{
            objectFit: 'initial',
            height: 'auto',
            top: 'auto',
            bottom: 0,
          }}
        />
      )}
    </Container>
  </Wrapper>
)

export default Hero
