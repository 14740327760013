import React from 'react'
import styled from 'styled-components'

const WrapperStyled = styled.section`
  width: 100%;
  margin: 0 auto auto;
  padding: 0;
  flex-grow: 1;
`

const Wrapper = props => {
  return <WrapperStyled>{props.children}</WrapperStyled>
}

export default Wrapper
