import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import config from '../utils/siteConfig'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import Wrapper from '../components/Wrapper'
import PageBody from '../components/PageBody'
import SEO from '../components/SEO'
import PageTitle from '../components/PageTitle'
import Container from '../components/Container'

const PortfolioTemplate = ({ data, location, pageContext }) => {
  const {
    title,
    description,
    slug,
    heroImage,
    body,
    backgroundColour,
    websiteUrl,
    ctaButtonText,
  } = data.contentfulPortfolio
  const postNode = data.contentfulPortfolio

  return (
    <Layout location={location} pageContext={pageContext}>
      <Helmet>
        <title>{`${title} - ${config.siteTitle}`}</title>
      </Helmet>
      <SEO pagePath={slug} postNode={postNode} postSEO />

      <Hero
        image={heroImage}
        height={'50vh'}
        backgroundColour={backgroundColour}
        title={title}
        subtitle={description.childContentfulRichText.html}
        websiteUrl={websiteUrl}
        ctaButtonText={ctaButtonText}
      />

      {body && (
        <Wrapper>
          <Container content={1}>
            <PageTitle withUnderline>{title}</PageTitle>
            <PageBody body={body} />
          </Container>
        </Wrapper>
      )}
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulPortfolio(slug: { eq: $slug }) {
      title
      slug
      backgroundColour
      websiteUrl
      ctaButtonText
      metaDescription {
        internal {
          content
        }
      }
      publishDate(formatString: "MMMM DD, YYYY")
      publishDateISO: publishDate(formatString: "YYYY-MM-DD")
      heroImage {
        title
        fluid(maxWidth: 1800) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
        ogimg: resize(width: 1800) {
          src
          width
          height
        }
      }
      cardImage {
        title
        fluid(maxWidth: 1800) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
        ogimg: resize(width: 1800) {
          src
          width
          height
        }
      }
      body {
        childMarkdownRemark {
          html
        }
      }
      description {
        childContentfulRichText {
          html
        }
      }
    }
  }
`

export default PortfolioTemplate
